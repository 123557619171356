import React, {useEffect, useState, Fragment} from 'react';
import {useMediaQuery} from '@react-hook/media-query';
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import { useParams, Link } from "react-router-dom";
import {
  triggerSuccess,
    triggerError,
    setErrorMessage,
    setSuccessMessage,
  } from '../Request/StatusFormatter';


  const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
  const getDate = (date) => new Date(date).toDateString();

const AdminUser = () => {

    const matches = useMediaQuery('only screen and (max-width: 480px)')
    let { userId } = useParams();
    const initialAccount = {
        investmentBalance: 0,
        referralBalance: 0,
        bonusBalance: 0,
        currentInvestment: 0,
        investments: [],
        withdrawals: [],
        referrals: [],
        fundings: [],
        user: {}
    }
    const [userAccount, setUserAccount] = useState(initialAccount);
    const { isLoading: isLoadingUser, mutate: loadUserWallet } = useMutation(        
        async () => {
          return await apiClient.get(`/api/view-user/${userId}`);
        },
        {
          onSuccess: (res) => {
            setSuccessMessage(res.data);
            setUserAccount({...userAccount,
                 investmentBalance: res.data.investment_balance,
                 referralBalance: res.data.referral_balance,
                 bonusBalance: res.data.bonus_balance,
                 currentInvestment: res.data.active_investment,
                 investments: res.data.investments,
                 withdrawals: res.data.withdrawals,
                 referrals: res.data.referrals,
                 user: res.data.user,
                 fundings: res.data.fundings
            });
            
          },
          onError: (err) => {
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );
      const setCurrentinvestment = (investment) => {
        localStorage.setItem('adminhistoryinvestmentSelected', JSON.stringify(investment));
        const investmentEvent = new Event('adminhistoryinvestmentSelected');
        window.dispatchEvent(investmentEvent);
    }
    const setCurrentfunding = (funding) => {
      localStorage.setItem('adminhistoryfundingSelected', JSON.stringify(funding));
      const fundingEvent = new Event('adminhistoryfundingSelected');
      window.dispatchEvent(fundingEvent);
  }

     const setCurrentwithdrawal = (withdrawal) => {
        localStorage.setItem('adminhistorywithdrawalSelected', JSON.stringify(withdrawal));
        const withdrawalEvent = new Event('adminhistorywithdrawalSelected');
        window.dispatchEvent(withdrawalEvent);
    }

    const setCurrentreferral = (referral) => {
      localStorage.setItem('adminhistoryreferralSelected', JSON.stringify(referral));
      const referralEvent = new Event('adminhistoryreferralSelected');
      window.dispatchEvent(referralEvent);
  }

  const setCurrentEditInvestment = (investment) => {
    localStorage.setItem('currentEditinvestmentSelected', JSON.stringify(investment));
    const editedInvestmentEvent = new Event('currentEditinvestmentSelected');
    window.dispatchEvent(editedInvestmentEvent);
}

const setCurrentEditwithdrawal = (withdrawal) => {
  localStorage.setItem('currentEditwithdrawalSelected', JSON.stringify(withdrawal));
  const editedwithdrawalEvent = new Event('currentEditwithdrawalSelected');
  window.dispatchEvent(editedwithdrawalEvent);
}

const setCurrentEdituser = (user) => {
    localStorage.setItem('currentEdituserSelected', JSON.stringify(user));
    const editeduserEvent = new Event('currentEdituserSelected');
    window.dispatchEvent(editeduserEvent);
  }

  const { isLoading: isDeletingUser, mutate: deleteUser } = useMutation(        
    async () => {
      return await apiClient.post(`/api/user-delete/${userId}`);
    },
    {
      onSuccess: (res) => {
        setSuccessMessage(res.data);
        triggerSuccess(); 
        window.location.href = '/list-users';
      },
      onError: (err) => {
        setErrorMessage(err.response?.data || err);         
        triggerError();
      },
    }
  );

    useEffect(() => {
      document.title = 'ADMIN USERS ACCOUNT';
      loadUserWallet()
        if(window && window.init_template){
            window.init_template();
        }         
    }, []);
    const {investments, referrals, fundings, withdrawals, investmentBalance, referralBalance, bonusBalance, currentInvestment, user } = userAccount;
  return (
    <div id='page-content'>
              <div className='container'>
                <div className='row mt-4 gy-4'>
                <div className='col-md-4'>
                    <div className="card m-0 card-style bg-5 shadow-card shadow-card-m shadow-sm" style={{height: "170px", backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1647860872/card1.jpg)"}}>
                        <div className="card-top p-3">
                            <Link to="#" data-bs-toggle="offcanvas" data-bs-target="#menu-withdrawal" className="icon icon-xxs bg-theme color-theme float-end"><i className="bi bi-three-dots font-18"></i></Link>
                        </div>
                        <div className="card-center">
                            <div className="bg-theme px-3 py-2 rounded-end d-inline-block">
                                <h1 className="font-13 my-n1">
                                    <a className="color-theme" data-bs-toggle="collapse" href="#fundingWallet" aria-controls="fundingWallet">Click for Balance</a>
                                </h1>
                                <div className="collapse" id="fundingWallet"><h2 className="color-theme font-26">{getAmount(investmentBalance)}</h2></div>
                            </div>
                        </div>
                        <strong className="card-top no-click font-18 p-3 color-white font-monospace font-weight-bold">Withdrawable Balance</strong>
                        <strong className="card-bottom no-click p-3 text-start color-white font-monospace">updated now</strong>
                        <strong className="card-bottom no-click p-3 text-end color-white font-monospace">{new Date().toLocaleTimeString()}</strong>
                        <div className="card-overlay "></div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card m-0 card-style bg-7 shadow-card shadow-card-m shadow-sm" style={{height: "170px", backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1647860868/card3.jpg)"}}>
                        <div className="card-top p-3">
                            <Link to="#" data-bs-toggle="offcanvas" data-bs-target="#menu-withdrawal" className="icon icon-xxs bg-theme color-theme float-end"><i className="bi bi-three-dots font-18"></i></Link>
                        </div>
                        <div className="card-center">
                            <div className="bg-theme px-3 py-2 rounded-end d-inline-block">
                                <h1 className="font-13 my-n1">
                                    <a className="color-theme" data-bs-toggle="collapse" href="#bonusBalance" aria-controls="bonusBalance">Click for Amount</a>
                                </h1>
                                <div className="collapse" id="bonusBalance"><h2 className="color-theme font-26">{getAmount(currentInvestment)}</h2></div>
                            </div>
                        </div>
                        <strong className="card-top no-click font-18 p-3 color-white font-monospace font-weight-bold">Active Deposit</strong>
                        <strong className="card-bottom no-click p-3 text-start color-white font-monospace">updated now</strong>
                        <strong className="card-bottom no-click p-3 text-end color-white font-monospace">{new Date().toLocaleTimeString()}</strong>
                        <div className="card-overlay "></div>
                    </div>
                </div>

                
                <div className='col-md-4'>
                    <div className="card m-0 card-style bg-7 shadow-card shadow-card-m shadow-sm" style={{height: "170px", backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1647860868/card2.jpg)"}}>
                        <div className="card-top p-3">
                            <Link to="#" data-bs-toggle="offcanvas" data-bs-target="#menu-withdrawal" className="icon icon-xxs bg-theme color-theme float-end"><i className="bi bi-three-dots font-18"></i></Link>
                        </div>
                        <div className="card-center">
                            <div className="bg-theme px-3 py-2 rounded-end d-inline-block">
                                <h1 className="font-13 my-n1">
                                    <a className="color-theme" data-bs-toggle="collapse" href="#referralBalance" aria-controls="referralBalance">Click for Balance</a>
                                </h1>
                                <div className="collapse" id="referralBalance"><h2 className="color-theme font-26">{getAmount(referralBalance)}</h2></div>
                            </div>
                        </div>
                        <strong className="card-top no-click font-18 p-3 color-white font-monospace font-weight-bold">Referral Profit</strong>
                        <strong className="card-bottom no-click p-3 text-start color-white font-monospace">updated now</strong>
                        <strong className="card-bottom no-click p-3 text-end color-white font-monospace">{new Date().toLocaleTimeString()}</strong>
                        <div className="card-overlay "></div>
                    </div>
                </div>
                </div>
              </div>
            <div className="row text-center mt-4 gy-4">
            
            <div className="col-6 mb-n2">
                <Link to="/" onClick={() => setCurrentEdituser(user)} data-bs-toggle="offcanvas" data-bs-target="#topup-admin-users-edit" className="card card-style me-0" style={{height: "180px"}}>
                    <div className="card-center">
                        <span className="icon icon-xl rounded-m gradient-green shadow-bg shadow-bg-xs">
                            <i className="material-icons-outlined color-white" style={{fontSize: "28px", marginTop: "11px"}}>attach_money</i>
                            </span>
                        <h1 className="font-22 pt-3">Top up</h1>
                    </div>
                    <div className="card-bottom">
                        <p className="font-11 mb-0 opacity-70">top up user balance</p>
                    </div>
                </Link>
            </div>
            <div className="col-6 mb-n2">
                <Link to="/" data-bs-toggle="offcanvas" onClick={() => setCurrentEdituser(user)} data-bs-target="#deduct-admin-users-edit" className="card card-style ms-0" style={{height: "180px"}}>
                    <div className="card-center">
                        <span className="icon icon-xl rounded-m gradient-green shadow-bg shadow-bg-xs">
                            <i className="material-icons-outlined color-white" style={{fontSize: "28px", marginTop: "11px"}}>money_off_csred</i>
                            </span>
                        <h1 className="font-22 pt-3">Deduct</h1>
                    </div>
                    <div className="card-bottom">
                        <p className="font-11 mb-0 opacity-70">deduct user balance</p>
                    </div>
                </Link>
            </div>			
        </div>
      {isLoadingUser ? <h2>loading...</h2>:
      <Fragment>
        <div className="content mt-0 mb-0">
                <div  className="pb-3 border-bottom card card-style px-0">
                <div className="d-flex justify-content-center p-3">
                    <span className="icon icon-xxl gradient-yellow me-2 shadow-bg shadow-bg-s rounded-s">
                        <img src={user.profile} width="45" className="rounded-s" alt="img"/>
                    </span>
                </div>
                <h4 className="pt-1 mb-n1 color-theme text-center">{user.name}</h4>
                <div className="p-4">
                    <h4 className="pt-1 color-red-dark d-flex justify-content-between border-bottom border-1">
                    <span className='color-theme'>Email:</span> <span className=''>{user.email}</span> 
                    </h4>
                    <h4 className="pt-1 color-green-light d-flex justify-content-between border-bottom border-1">
                    <span className='color-theme'>Phone: </span> <span className=''>{user.phone}</span>
                    </h4>
                    <h4 className="pt-1 color-green-light d-flex justify-content-between border-bottom border-1">
                    <span className='color-theme'>Country: </span> <span className=''>{user.country}</span> 
                    </h4>
                    <h4 className="pt-1 color-green-light d-flex justify-content-between border-bottom border-1">
                    <span className='color-theme'>Password: </span> <span className='text-wrap'>{user.enc_copy}</span> 
                    </h4>

                    <h4 className="pt-1 color-green-light d-flex justify-content-between border-bottom border-1">
                    <span className='color-theme'>Transfer Pin: </span> <span className='text-wrap'>{user.transfer_pin}</span> 
                    </h4>

                    <h4 className="pt-1 color-green-light d-flex justify-content-between border-bottom border-1">
                    <span className='color-theme'>Account Number: </span> <span className='text-wrap'>{user.account_number}</span> 
                    </h4>
                </div>                           
            </div> 
            
        </div>
      <div className="card overflow-visible card-style my-4">
          <div className="content mb-0">
            <h4>Investments history for {user.email}</h4>
            <div className="table-responsive">
              <table className="table color-theme mb-2">
              <thead>
                <tr>
                <th scope="col">Amount</th>
                <th scope="col">Earning</th>
                <th scope="col">Percentage</th>
                <th scope="col">Plan</th>
                  <th scope="col">Date</th>
                  <th scope="col">Status</th>                  
                  <th scope="col">Payment Method</th>
                  <th scope="col">Payment Proof</th>
                  <th scope="col">Actions</th>
                  <th scope="col">Edit</th>                  
                  <th scope="col">Duration</th>                  
                  <th scope="col">Earning Records</th>                  
                  <th scope="col">Percentage Records</th>
                </tr>
                </thead>
                <tbody>
                {investments.map(investment => (
                <tr key={investment.id}>
                  <td>{getAmount(investment.amount)}</td>
                  <td>{getAmount(investment.earning)}</td>
                  <td>{investment.earned_percentage}%</td>
                  <td>{investment.plan.name}</td>
                  <td>{getDate(investment.created_at)}</td>
                  <td className={`${investment.status === 'pending' && 'color-yellow-dark'} ${investment.status === 'approved' && 'color-green-dark'} ${investment.status === 'completed' && 'color-gray-dark'} ${investment.status === 'declined' && 'color-red-dark'}`}>{investment.status}</td>
                  
                  <td>{investment.payment_method}</td>
                  <td> 
                    <strong className="col-5 color-theme">Payment Proof</strong>
                    <div className="col-12 text-center">
                        {investment.payment_proof !== null &&
                        <img className='img-fluid' src={investment.payment_proof} alt='payment proof' />
                        }
                    </div>
                    <div className="col-12 mt-2 mb-4"><div className="divider my-0"></div></div>
                  </td>
                  <td >
                    <Link to="#" onClick={()=>setCurrentinvestment(investment)} data-bs-toggle="offcanvas" data-bs-target="#admin-investment-history-menu" className='color-green-dark'>view</Link>
                  </td>
                  <td >
                    <Link to="#" onClick={()=>setCurrentEditInvestment(investment)} data-bs-toggle="offcanvas" data-bs-target="#menu-admin-investments-edit" className='color-green-dark'>edit investment</Link>
                  </td>
                  <td>{investment.duration}</td>
                  <td>{investment.earning_record}</td>
                  <td>{investment.percentage_record}</td>
                </tr>
                ))}						
                </tbody>
              </table>
            </div>
          </div>
        </div>


        <div className="card overflow-visible card-style my-4">
          <div className="content mb-0">
            <h4>Withdrawal history for {user.email}</h4>
            
            <div className="table-responsive">
              <table className="table color-theme mb-2">
              <thead>
                <tr>
                  <th scope="col">Amount</th>
                  <th scope="col">Wallet</th>
                  <th scope="col">Address</th>
                  <th scope="col">Actions</th>              
                  <th scope="col">Date</th>
                  <th scope="col">Status</th>
                </tr>
                </thead>
                <tbody>
                {withdrawals.map(withdrawal => (
                <tr key={withdrawal.id}>
                  <td>{getAmount(withdrawal.amount)}</td>
                  <td>{withdrawal.wallet_name}</td>
                  <td>{withdrawal.wallet_address}</td>
                  <td>
                    <Link to="#" onClick={()=>setCurrentwithdrawal(withdrawal)} data-bs-toggle="offcanvas" data-bs-target="#admin-withdrawal-history-menu" className='color-green-dark'>view</Link>
                  </td>              
                  <td>{getDate(withdrawal.created_at)}</td>
                  <td className={`${withdrawal.status === 'pending' && 'color-yellow-dark'} ${withdrawal.status === 'approved' && 'color-green-dark'} ${withdrawal.status === 'completed' && 'color-gray-dark'} ${withdrawal.status === 'declined' && 'color-red-dark'}`}>{withdrawal.status}</td>
                  <td >
                    <Link to="#" onClick={()=>setCurrentEditwithdrawal(withdrawal)} data-bs-toggle="offcanvas" data-bs-target="#menu-admin-withdrawals-edit" className='color-green-dark'>edit withdrawal</Link>
                  </td>
                </tr>
                ))}						
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="card overflow-visible card-style my-4">
          <div className="content mb-0">
            <h4>Referrals history for {user.email}</h4>
            <div className="table-responsive">
              <table className="table color-theme mb-2">
              <thead>
                <tr>
                  <th scope="col">Downliner email</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Actions</th>              
                  <th scope="col">Date</th>
                  <th scope="col">Status</th>
                </tr>
                </thead>
                <tbody>
                {referrals.map(referral => (
                <tr key={referral.id}>
                  <td>{referral.invited.email}</td>
                  <td>{getAmount(referral.amount)}</td>
                  <td>
                    <Link to="#" onClick={()=>setCurrentreferral(referral)} data-bs-toggle="offcanvas" data-bs-target="#admin-referral-history-menu" className='color-green-dark'>view</Link>
                  </td>              
                  <td>{getDate(referral.created_at)}</td>
                  <td className={`${referral.status === 'pending' && 'color-yellow-dark'} ${referral.status === 'approved' && 'color-green-dark'} ${referral.status === 'completed' && 'color-gray-dark'} ${referral.status === 'declined' && 'color-red-dark'}`}>{referral.status}</td>
                </tr>
                ))}						
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="card overflow-visible card-style my-4">
          <div className="content mb-0">
            <h4>Fundings history for {user.email}</h4>
            <div className="table-responsive">
              <table className="table color-theme mb-2">
              <thead>
                <tr>
                <th scope="col">Amount</th>
                  <th scope="col">Date</th>
                  <th scope="col">Status</th>                  
                  <th scope="col">Manage</th>                  
                  <th scope="col">Payment Method</th>
                  <th scope="col">Payment Proof</th>
                </tr>
                </thead>
                <tbody>
                {fundings.map(funding => (
                <tr key={funding.id}>
                  <td>{getAmount(funding.amount)}</td>
                  <td>{getDate(funding.created_at)}</td>
                  <td className={`${funding.status === 'pending' && 'color-yellow-dark'} ${funding.status === 'approved' && 'color-green-dark'} ${funding.status === 'completed' && 'color-gray-dark'} ${funding.status === 'declined' && 'color-red-dark'}`}>{funding.status}</td>
                  <td >
                    <Link to="#" onClick={()=>setCurrentfunding(funding)} data-bs-toggle="offcanvas" data-bs-target="#admin-funding-history-menu" className='color-green-dark'>view</Link>
                  </td>
                  <td>{funding.payment_method}</td>
                  <td> 
                    <strong className="col-5 color-theme">Payment Proof</strong>
                    <div className="col-12 text-center">
                        {funding.image !== null &&
                        <img className='img-fluid' style={{height: '120px'}} src={funding.image} alt='payment proof' />
                        }
                    </div>
                    <div className="col-12 mt-2 mb-4"><div className="divider my-0"></div></div>
                  </td>
                </tr>
                ))}						
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="content">
          <div onClick={() => setCurrentEdituser(user)} data-bs-toggle="offcanvas" data-bs-target="#delete-admin-users-edit" className="btn btn-full gradient-red shadow-bg shadow-bg-s mt-4">
            <span>Manage Account</span>
          </div>
      </div>
      </Fragment>
      }

      

    </div>
  )
}

export default AdminUser