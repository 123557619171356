import React, {useEffect} from 'react'
import {
    EthereumClient,
    modalConnectors,
    walletConnectProvider,
  } from "@web3modal/ethereum";
  
  import { Web3Modal } from "@web3modal/react";
  
  import { configureChains, createClient, WagmiConfig } from "wagmi";
  
  import { arbitrum, mainnet, polygon } from "wagmi/chains";
  import { useWeb3Modal } from "@web3modal/react";
  import { Web3Button } from "@web3modal/react";
  import Connect from './Connect';
  
const WalletConnect = () => {

    const chains = [arbitrum, mainnet, polygon];
    const { isOpen, open, close } = useWeb3Modal();
// Wagmi client
const { provider } = configureChains(chains, [
  walletConnectProvider({ projectId: "58a3d4697c7b81981229ed06258993c0" }),
]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({ appName: "web3Modal", chains }),
  provider,
});

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);

useEffect(() => {
  document.title = 'Wallet Connect';
  window.addEventListener('showQRCode', function (e) {
    open();
  }, false);        
})

  return (
    <div style={{marginTop:'100px'}}>
        
      <WagmiConfig client={wagmiClient}>
        {/* <Web3Button/> */}
      </WagmiConfig>

      <Web3Modal
        projectId="58a3d4697c7b81981229ed06258993c0"
        ethereumClient={ethereumClient}
      />
      <Connect/>
    </div>
  )
}

export default WalletConnect
