import React from 'react';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const LoginAlert = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const formatStatusMessages = () => {

        window.addEventListener('updatedSuccessStorage', function (e) {
            let successBag  = localStorage.getItem('success');
            if (successBag != null) {
                successBag = JSON.parse(successBag);
                setSuccessMessage(successBag);
            }
        }, false);

        window.addEventListener('updatedErrorStorage', function (e) {
            let errorBag = localStorage.getItem('error');
            if (errorBag != null) {
                errorBag = JSON.parse(errorBag);
                setErrorMessage(errorBag);
            }
        }, false);
       
    }  
    useEffect(() => {
        if(window && window.init_template){
            window.init_template();
        }
        formatStatusMessages();
    });
  return (
    <div>

        <div id="error-modal" style={{width:"320px"}} className="offcanvas offcanvas-modal offcanvas-detached rounded-m">
          <div className="content text-center">
            <i style={{fontSize:"65px"}} className="scale-box bi bi-x-circle-fill color-red-dark shadow-s rounded-circle p-0 mt-3 mb-3 d-inline-block"></i>
            <h1 className="pt-3 font-28">Failed</h1>
            <p className="font-14 mb-4">
              {errorMessage !=='' && <span>{errorMessage.message || errorMessage}</span>}
            </p>
            <Link to='/' data-bs-toggle="offcanvas" data-bs-target="#error-modal" className="close-modal btn btn-full gradient-red shadow-bg shadow-bg-xs">Okay</Link>
          </div>
        </div>

        <div id="success-modal" style={{width:"320px"}} className="offcanvas offcanvas-modal offcanvas-detached rounded-m">
            <div className="content text-center">
                <i style={{fontSize:"65px"}} className="scale-box bi bi-check-circle-fill color-green-dark shadow-s rounded-circle p-0 mt-3 mb-3 d-inline-block"></i>
                <h1 className="pt-3 font-28">Successful</h1>
                <p className="font-14">
                    {successMessage !== '' && <span>{successMessage.message}</span>}
                </p>
                <Link to='/' data-bs-toggle="offcanvas" data-bs-target="#success-modal"  className="close-modal btn btn-full gradient-green shadow-bg shadow-bg-xs">Okay</Link>
            </div>
        </div>

        <div id="pending-modal" style={{width: "320px"}} className="offcanvas offcanvas-modal offcanvas-detached rounded-m">
		<div className="content text-center">
			<i style={{fontSize:"65px"}} className="scale-box bi bi-question-circle-fill color-blue-dark shadow-s rounded-circle p-0 mt-3 mb-3 d-inline-block"></i>
			<h1 className="pt-3 font-28">Sending...</h1>
			<p className="font-14">
				We are processing this request, please wait!
			</p>
			<Link to="/" data-bs-toggle="offcanvas" data-bs-target="#pending-modal" className="btn btn-full gradient-blue shadow-bg shadow-bg-xs">Close</Link>
		</div>
	</div>

    </div>
  )
}

export default LoginAlert