import React, {useEffect, useState} from 'react'
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import {
  triggerSuccess,
  triggerError,
  setErrorMessage,
  setSuccessMessage,
} from '../Request/StatusFormatter';

const ManagePlan = () => {    
const initialplan = {
    min_amount: '',
    max_amount: '',
    durations_copy: '',
    name: '',
    ref_bonus: '',
    description: '',
    daily_roi: '',
    daily_roi_max: ''
}
const [planForm, setplanForm] = useState(initialplan);
const [currentplan, setCurrentplan] = useState('');

const onChange = (e) =>
setplanForm({ ...planForm, [e.target.name]: e.target.value });

const { isLoading: isSendingRequest, mutate: postplan } = useMutation(
    async () => {
      return await apiClient.post(`/api/update-plan/${currentplan}`, {
        min_amount: planForm.min_amount,
        max_amount: planForm.max_amount,
        durations_copy: planForm.durations_copy,
        name: planForm.name,
        ref_bonus: planForm.ref_bonus,
        description: planForm.description,
        daily_roi: planForm.daily_roi,
        daily_roi_max: planForm.daily_roi_max
      });
    },
    {
      onSuccess: (res) => {
        setSuccessMessage(res.data);
        triggerSuccess(); 
        
      },
      onError: (err) => {
        setErrorMessage(err.response?.data || err);         
        triggerError();
      },
    }
  );

useEffect(() => {
    window.addEventListener('adminhistoryplanSelected', function (e) {
        let plan = localStorage.getItem('adminhistoryplanSelected');
        if (plan != null) {
            plan = JSON.parse(plan);

            setCurrentplan(plan.id);
            setplanForm({...planForm,
                min_amount: plan.min_amount,
                max_amount: plan.max_amount,
                durations_copy: plan.durations_copy,
                name: plan.name,
                ref_bonus: plan.ref_bonus,
                description: plan.description,
                daily_roi: plan.daily_roi_percent,
                daily_roi_max: plan.max_daily_roi_percent
            })
        }
    }, false);        
  })

  const {min_amount, max_amount, durations_copy, name, ref_bonus, description, daily_roi, daily_roi_max } = planForm;
  return (
    <div id="admin-plan-history-menu" className="offcanvas offcanvas-bottom offcanvas-attached">
            <div className="menu-size" style={{height: "640px"}}>
                <div className="d-flex mx-3 mt-3 py-1">
                    <div className="align-self-center">
                        <h1 className="mb-0">{name}</h1>
                    </div>
                    <div className="align-self-center ms-auto">
                        <a href="/#" className= "ps-4 shadow-0 me-n2" data-bs-dismiss="offcanvas">
                            <i className="bi bi-x color-red-dark font-26 line-height-xl"></i>
                        </a>
                    </div>
                </div>
                <div className="divider divider-margins mt-3"></div>
                <form key={currentplan} className="content mt-0">
                <div className="pb-2"></div>
                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-code font-14"></i>
                            <input onChange={onChange} defaultValue={min_amount} type="text" name="min_amount" className="form-control rounded-xs bg-theme color-theme" id="name" placeholder="minimum amount"/>
                            <label htmlFor="min_amount" className="color-highlight form-label-always-">Minimum amount</label>
                            <span>(minimum amount)</span>
                        </div>
                        <div className="pb-2"></div>

                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-code font-14"></i>
                            <input onChange={onChange} defaultValue={max_amount} type="text" name="max_amount" className="form-control rounded-xs bg-theme color-theme" id="max_amount" placeholder="Maximum amount"/>
                            <label htmlFor="max_amount" className="color-highlight form-label-always-">Maximum Amount</label>
                            <span>(Maximum Amount)</span>
                        </div>
                        <div className="pb-2"></div>

                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-code font-14"></i>
                            <textarea onChange={onChange} defaultValue={durations_copy} type="text" name="durations_copy" className="form-control rounded-xs bg-theme color-theme" id="durations_copy" placeholder="durations"></textarea>
                            <label htmlFor="durations_copy" className="color-highlight form-label-always-">durations</label>
                            <span>(durations)</span>
                        </div>
                        <div className="pb-2"></div>


                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-code font-14"></i>
                            <input onChange={onChange} defaultValue={name} type="text" name="name" className="form-control rounded-xs bg-theme color-theme" id="name" placeholder="namet"/>
                            <label htmlFor="name" className="color-highlight form-label-always-">Name</label>
                            <span>(name)</span>
                        </div>
                        <div className="pb-2"></div>

                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-code font-14"></i>
                            <input onChange={onChange} defaultValue={ref_bonus} type="text" name="ref_bonus" className="form-control rounded-xs bg-theme color-theme" id="ref_bonus" placeholder="Referral Bonus"/>
                            <label htmlFor="ref_bonus" className="color-highlight form-label-always-">Referral Bonus</label>
                            <span>(Referral Bonus)</span>
                        </div>
                        <div className="pb-2"></div>

                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-code font-14"></i>
                            <textarea onChange={onChange} defaultValue={description} type="text" name="description" className="form-control rounded-xs bg-theme color-theme" id="description" placeholder="description"></textarea>
                            <label htmlFor="description" className="color-highlight form-label-always-">description</label>
                            <span>(description)</span>
                        </div>
                        <div className="pb-2"></div>

                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-code font-14"></i>
                            <input onChange={onChange} defaultValue={daily_roi} type="text" name="daily_roi" className="form-control rounded-xs bg-theme color-theme" id="daily_roi" placeholder="Daily ROI"/>
                            <label htmlFor="daily_roi" className="color-highlight form-label-always-">Daily ROI</label>
                            <span>(Daily ROI)</span>
                        </div>
                        <div className="pb-2"></div>

                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-code font-14"></i>
                            <input onChange={onChange} defaultValue={daily_roi_max} type="text" name="daily_roi_max" className="form-control rounded-xs bg-theme color-theme" id="daily_roi_max" placeholder="Daily Maximum ROI"/>
                            <label htmlFor="daily_roi_max" className="color-highlight form-label-always-">Daily Maximum ROI</label>
                            <span>(Daily Maximum ROI)</span>
                        </div>
                        <div className="pb-2"></div>

                        
                        <div onClick={postplan} className="btn btn-full gradient-green rounded-s shadow-bg shadow-bg-s mb-4">
                            {isSendingRequest? <span>updating...</span>
                            :
                            <span>Update plan</span>
                            }                            
                        </div>                     
                </form>                
            </div>
        </div>
  )
}

export default ManagePlan