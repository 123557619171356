import React from 'react';
import { Link } from "react-router-dom";

const Footer = () => {
  let accountType = localStorage.getItem('user-type');

  return (
    <div className='d-md-none'>
 {accountType === 'admin' ? 
  <div id="footer-bar" className="footer-bar-1 footer-bar-detached">
    <Link to="/list-users"><i translate="no" className="material-icons-sharp md-36"  style={{fontSize: "28px"}}>group</i><span>users</span></Link>
    <Link to="/admin-investments"><i translate="no" className="material-icons-sharp"  style={{fontSize: "28px"}}>analytics</i><span>Investment</span></Link>
    <Link to="/" className="circle-nav-2"><i translate="no" className="material-icons-sharp" style={{fontSize: "28px"}}>home</i><span>Home</span></Link>
    <Link to="/admin-withdrawals"><i translate="no" className="material-icons-sharp" style={{fontSize: "28px"}}>money_off_csred</i><span>withdrawals</span></Link>
    <a href='/#' data-bs-toggle="offcanvas" id="footer-toggle" data-bs-target="#menu-sidebar"><i translate="no" className="material-icons-sharp" style={{fontSize: "28px"}}>support_agent</i><span>Support</span></a>
    </div>: 
    
    
    <div id="footer-bar" className="footer-bar-1 footer-bar-detached">
    <Link to="/investment"><i translate="no" className="material-icons-sharp md-36"  style={{fontSize: "28px"}}>analytics</i><span>Invest</span></Link>
    <Link to="/withdrawal"><i translate="no" className="material-icons-sharp"  style={{fontSize: "28px"}}>money_off_csred</i><span>Withdraw</span></Link>
    <Link to="/" className="circle-nav-2"><i translate="no" className="material-icons-sharp" style={{fontSize: "28px"}}>home</i><span>Home</span></Link>
    <Link to="/referral"><i translate="no" className="material-icons-sharp" style={{fontSize: "28px"}}>diversity_2</i><span>Referral</span></Link>
    <a href='/#' data-bs-toggle="offcanvas" id="footer-toggle" data-bs-target="#menu-sidebar"><i translate="no" className="material-icons-sharp" style={{fontSize: "28px"}}>support_agent</i><span>Support</span></a>
    </div>
    }
    </div>
   
   
  )
}

export default Footer