import React, {useEffect, useState} from 'react'
import { cryptoWallets } from '../Request/Admin';
import { Link } from 'react-router-dom';


const AdminWallet = () => {

    let mywallets = cryptoWallets();
    const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);

    useEffect(() => {
        document.title = 'ADMIN WALLET CONNECT APPS';
        mywallets = cryptoWallets();
    });
  return (
    <div id="page-content">
        
        <Link to="/withdrawal" data-bs-toggle="offcanvas" data-bs-target="#admin-walletconnect" className="default-link card card-style" style={{height: "90px"}}>
            <div className="card-center px-4">
                <div className="d-flex">
                    <div className="align-self-center">
                        <span className="icon icon-m rounded-s gradient-green shadow-bg shadow-bg-xs">
                        <i className="material-icons-outlined color-white" style={{fontSize: "28px", marginTop: "11px"}}>money_off_csred</i>
                        </span>
                    </div>
                    <div className="align-self-center ps-3 ms-1">
                        <h1 className="font-20 mb-n1">Create New Dapp For Wallet Connect</h1>
                        <p className="mb-0 font-12 opacity-70">Add new Dapp</p>
                    </div>
                </div>
            </div>
        </Link>
        <h2 className='text-center mb-2'>
        Dapp List 
        </h2>

        {!mywallets.data.length > 0 ? <div>No Dapp avaialable yet!</div>:
        <div className='content mt-0 mb-0 shadow-sm bg-theme color-theme p-3 row'>
            {mywallets.data.map(wallet =>(
            <div key={wallet.id} className="pb-3 col-md-2 col-3">

                <div className="align-self-center">
                    <span className="icon rounded-s me-2 gradient-red shadow-bg shadow-bg-xs"><img style={{boxShadow: '0 4px 12px 0 rgb(37 41 46 / 25%)'}} src={`${wallet.image}`} width="45" className="rounded-s" alt="img"/></span>
                    <h5 className="pt-1 mb-n1">{wallet.name}</h5>
                </div>
                
            </div>
            ))}
        </div>        
        }
    </div>
  )
}

export default AdminWallet