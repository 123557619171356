import React, {useState, useEffect} from 'react';
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import {
    triggerSuccess,
    triggerError,
    setErrorMessage,
    setSuccessMessage,
  } from '../Request/StatusFormatter';
  import InvestmentHistory from '../Investment/InvestmentHistory';
  import ChartBalance from '../Reinvestment/ChartBalance';
  import Settings from '../Settings/Settings';

const Profile = () => {
  const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);

    let user = localStorage.getItem('delox-user');
    if (user != null) {
        user = JSON.parse(user);
    }
    
    const initialImage = {
        image: null
    }
    const [formImage, setImage] = useState(initialImage);
    const  onFileChange = (event) => { 
        setImage({...formImage, image: event.target.files[0]});
      }; 

      const { isLoading: isSendingRequest, mutate: postFunding } = useMutation(
        async () => {
            
		const formData = new FormData(); 
		formData.append('image', formImage.image);
        return await apiClient.post('/api/profile-update', formData);
        },
        {
          onSuccess: (res) => {
            setSuccessMessage(res.data);
            triggerSuccess();  
            localStorage.setItem('delox-user', JSON.stringify(res.data.user));   
            const uploadEvent = new Event('profileUploaded');
            window.dispatchEvent(uploadEvent);       
          },
          onError: (err) => {
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );
      useEffect(()=>{
        document.title = 'USERS PROFILE';
        window.addEventListener('profileUploaded', function (e) {
            let localUser = localStorage.getItem('delox-user');
            if (localUser != null) {
                localUser = JSON.parse(localUser);
                user = localUser;
            }

              
        }, false);
    })
  return (
    <div id="page-content">
        <div className="notch-clear"></div>
       <div className="pt-5 mt-4"></div>
       <div className="card card-style overflow-visible mt-5">
           <div className="mt-n5"></div>
           {formImage.image === null ? 
           <img style={{width: "180px", height: "180px"}} src={user.profile} alt="profile" className="mx-auto rounded-circle mt-n5 shadow-l"/>
           :
           <img style={{width: "180px", height: "180px"}} src={URL.createObjectURL(formImage.image)} alt="profile" className=" mx-auto rounded-circle mt-n5 shadow-l"/>
           }
           <input onChange={onFileChange} type="file" id='profile' name="profile" accept="image/*" style={{display: "none"}}/>
            <label className='text-center' htmlFor='profile' style={{transform: "translate(20px, -20px)", zIndex: '20'}}>
           <span translate="no" className="material-icons-outlined color-theme" style={{fontSize: "38px"}} >add_a_photo</span>
           </label>
            {formImage.image !== null &&
                <div className='text-center p-8 mb-4 mt-n2'>
                    <button onClick={postFunding} className='btn btn-sm gradient-green'>
                        {isSendingRequest ? <span>uploading...</span>: <span>update profile</span>}
                    </button>
                </div>
            }
           <h1 className="color-theme text-center font-30 pt-0 mb-0">{user.name}</h1>
           
       </div>
        <Settings/>
    </div>
  )
}

export default Profile