
import React, {useEffect, useState, Fragment} from 'react';
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import {
    triggerSuccess,
    triggerError,
    setErrorMessage,
    setSuccessMessage,
  } from '../Request/StatusFormatter';

const Manageshares = () => {

    

    const [shares, setshares] = useState('');   
    const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
    const getDate = (date) => new Date(date).toDateString();

    const { isLoading: isApprovingshares, mutate: approveshares } = useMutation(        
        async () => {
          return await apiClient.post(`/api/approve-shares/${shares.id}`);
        },
        {
          onSuccess: (res) => {
            setSuccessMessage(res.data);
            triggerSuccess(); 
            
          },
          onError: (err) => {
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );

    const { isLoading: isDceliningshares, mutate: declineshares } = useMutation(        
        async () => {
          return await apiClient.post(`/api/decline-shares/${shares.id}`);
        },
        {
          onSuccess: (res) => {
            setSuccessMessage(res.data);
            triggerSuccess(); 
            
          },
          onError: (err) => {
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );
    
    useEffect(() => {
        window.addEventListener('adminhistorysharesSelected', function (e) {
            let selectedshares = localStorage.getItem('adminhistorysharesSelected');
            if (selectedshares != null) {
                selectedshares = JSON.parse(selectedshares); 
                setshares(selectedshares);
            }
        }, false);
    })
  return (
    <div>
        <div id="admin-shares-history-menu" className="offcanvas offcanvas-bottom offcanvas-attached">
            <div className="menu-size" style={{height:"480px"}}>
                {shares !== '' &&
                <div className="content">
                <a href="/#" className="d-flex py-1 pb-4">
                    <div className="align-self-center">
                        <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs"><i className="bi bi-person-circle font-18 color-white"></i></span>
                    </div>
                    <div className="align-self-center ps-1">
                        <h5 className="pt-1 mb-n1">{shares.user.email}</h5>
                    </div>
                </a>
                <div className="row">
                    <strong className="col-5 color-theme">Shareholder Name</strong>
                    <strong className="col-7 text-end">{shares.user.name}</strong>
                    <div className="col-12 mt-2 mb-2"><div className="divider my-0"></div></div>

                    <strong className="col-5 color-theme">Application Date </strong>
                    <strong className="col-7 text-end">{getDate(shares.created_at)}</strong>
                    <div className="col-12 mt-2 mb-2"><div className="divider my-0"></div></div>

                    <strong className="col-5 color-theme">Application Type </strong>
                    <strong className="col-7 text-end">{shares.type}</strong>
                    <div className="col-12 mt-2 mb-2"><div className="divider my-0"></div></div>


                    {(shares.status === 'pending' || shares.status === 'waiting approval' )&&
                    <Fragment>
                        <div className="col-6">
                            <div onClick={approveshares} className="btn btn-s btn-full gradient-green shadow-bg shadow-bg-xs">
                            {isApprovingshares ? <span>Approving...</span>: <span>Approve</span>} 
                            </div>
                        </div>
                        <div className="col-6">
                            <div onClick={declineshares} className="btn btn-s btn-full gradient-red shadow-bg shadow-bg-xs">
                                {isDceliningshares ? <span>Declining...</span>: <span>Decline</span>} 
                            </div>
                        </div>
                    </Fragment>                    
                    }
                </div>
            </div>
                }
            </div>
        </div>
    </div>
  )
}

export default Manageshares