import React, {useEffect, useState} from 'react'
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import {
  triggerSuccess,
  triggerError,
  setErrorMessage,
  setSuccessMessage,
} from '../Request/StatusFormatter';

const Topup = () => {    
const initialuser = {
            amount: '',
            user: {}
        }
const [userForm, setuserForm] = useState(initialuser);
const [currentuser, setCurrentuser] = useState('');

const onChange = (e) =>
setuserForm({ ...userForm, [e.target.name]: e.target.value });

const { isLoading: isSendingRequest, mutate: postuser } = useMutation(
    async () => {
      return await apiClient.post(`/api/topup-user/${currentuser}`, {
        amount: userForm.amount
      });
    },
    {
      onSuccess: (res) => {
        setSuccessMessage(res.data);
        triggerSuccess(); 
        
      },
      onError: (err) => {
        setErrorMessage(err.response?.data || err);         
        triggerError();
      },
    }
  );

useEffect(() => {
    window.addEventListener('currentEdituserSelected', function (e) {
        let user = localStorage.getItem('currentEdituserSelected');
        if (user != null) {
            user = JSON.parse(user);
            setCurrentuser(user.id);
            setuserForm({...userForm,
                amount: 0,
                user: user
            })
        }
    }, false);        
  })
  const {user} =userForm;
  return (
    <div id="topup-admin-users-edit" className="offcanvas offcanvas-bottom offcanvas-detached rounded-m">
            <div className="menu-size" style={{height: "540px"}}>
                <div className="d-flex mx-3 mt-3 py-1">
                    <div className="align-self-center">
                        <h1 className="mb-0">{user.name}</h1>
                    </div>
                    <div className="align-self-center ms-auto">
                        <a href="/#" className= "ps-4 shadow-0 me-n2" data-bs-dismiss="offcanvas">
                            <i className="bi bi-x color-red-dark font-26 line-height-xl"></i>
                        </a>
                    </div>
                </div>
                <div className="divider divider-margins mt-3"></div>
                <form key={currentuser} className="content mt-0">
                <div className="pb-2"></div>
                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-code font-14"></i>
                            <input onChange={onChange} type="text" name="amount" className="form-control rounded-xs bg-theme color-theme" id="name" placeholder="amount"/>
                            <label htmlFor="amount" className="color-highlight form-label-always-">amount</label>
                            <span>(amount)</span>
                        </div>
                        
                        <div className="pb-2"></div>
                        <div onClick={postuser} className="btn btn-full gradient-green rounded-s shadow-bg shadow-bg-s mb-4">
                            {isSendingRequest? <span>toping...</span>
                            :
                            <span>Top up user</span>
                            }                            
                        </div>                     
                </form>                
            </div>
        </div>
  )
}

export default Topup