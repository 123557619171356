import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {withdrawalsHistory} from '../Request/Admin';

const Adminwithdrawal = () => {
    let mywithdrawalHistory = withdrawalsHistory();

    const [pendingwithdrawals, setPendingwithdrawal] = useState(0);
    const [activewithdrawal, setActivewithdrawal] = useState(0);
    const [completedwithdrawal, setcompletedwithdrawal] = useState(0);
    const getDate = (date) => new Date(date).toDateString();

    const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
    const setCurrentwithdrawal = (withdrawal) => {
        localStorage.setItem('adminhistorywithdrawalSelected', JSON.stringify(withdrawal));
        const withdrawalEvent = new Event('adminhistorywithdrawalSelected');
        window.dispatchEvent(withdrawalEvent);
    }
   
    useEffect(() => {
        document.title = 'ADMIN WITHDRAWALS HISTORY';
        mywithdrawalHistory = withdrawalsHistory();

        let pendingNum = 0;        
        let activeNum = 0;        
        let completedNum = 0;  

        mywithdrawalHistory.forEach(withdrawal => {
                
                if (withdrawal.status === 'approved') {
                    activeNum += parseInt(withdrawal.amount);
                }
                if (withdrawal.status === 'completed') {
                    completedNum += parseInt(withdrawal.amount);
                }
                if (withdrawal.status === 'declined') {
                    completedNum += parseInt(withdrawal.amount);
                }
                if (withdrawal.status === 'pending') {
                    pendingNum += parseInt(withdrawal.amount);
                }
                if (withdrawal.status === 'waiting approval') {
                    pendingNum += parseInt(withdrawal.amount);
                }
            
        });
        
        setActivewithdrawal(activeNum);
        setcompletedwithdrawal(completedNum);
        setPendingwithdrawal(pendingNum);
        
        if(window && window.init_template){
            window.init_template();
        }  
    }, [mywithdrawalHistory, activewithdrawal, completedwithdrawal, pendingwithdrawals]);

  return (
    <div id="page-content" >        
        <h2 className='text-center mb-2'>
            withdrawals History
        </h2>
        <div className="card card-style">
            <div className="content mb-0">
                <div className="tabs tabs-pill" id="lend-customers">
                    <div className="tab-controls rounded-m p-1 overflow-visible">
                        <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#pending-customers" aria-expanded="true">Pending</a>
                        <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#active-customers" aria-expanded="false">Active</a>
                        <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#completed-customers" aria-expanded="false">Declined</a>
                    </div>
                    <div className="mt-3"></div>
                    {/* <!-- Tab Bills List --> */}
                    <div className="collapse show" id="pending-customers" data-bs-parent="#lend-customers">
                        <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
                        <div className="content mt-0 mb-0">
                            {!pendingwithdrawals > 0 ?
                            <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                                <div className="content my-3">
                                    <div className="d-flex">
                                        <div className="align-self-center no-click">
                                            <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                                        </div>
                                        <div className="align-self-center no-click">
                                            <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                                No pending withdrawal records found for now
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                                mywithdrawalHistory.map(withdrawal =>(
                                    (withdrawal.status === 'pending' || withdrawal.status === 'waiting approval') &&
                                    <Link key={withdrawal.id} onClick={()=>setCurrentwithdrawal(withdrawal)} data-bs-toggle="offcanvas" data-bs-target="#admin-withdrawal-history-menu"  to="#" className="d-flex pb-3">
                                        <div className="align-self-center">
                                            <span className="icon rounded-s me-2 gradient-red shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                                        </div>
                                        <div className="align-self-center ps-1">
                                            <h5 className="pt-1 mb-n1">{getAmount(withdrawal.amount)}</h5>
                                            <p className="mb-0 font-11 opacity-50">{getDate(withdrawal.created_at)}</p>
                                        </div>
                                        <div className="align-self-center ms-auto text-end">
                                            <h4 className="pt-1 mb-n1 color-red-dark">{withdrawal.user.name}</h4>
                                            <p className="mb-0 font-12 opacity-50">{withdrawal.user.email}</p>
                                        </div>
                                    </Link>
                                ))                
                                        
                            }
                        </div>                                          
                </div>

                <div className="collapse" id="active-customers" data-bs-parent="#lend-customers">
                        <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
                        <div className="content mt-0 mb-0">
                            {!activewithdrawal > 0 ?
                            <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                                <div className="content my-3">
                                    <div className="d-flex">
                                        <div className="align-self-center no-click">
                                            <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                                        </div>
                                        <div className="align-self-center no-click">
                                            <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                                No active withdrawal records found for now
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                                mywithdrawalHistory.map(withdrawal =>(
                                    withdrawal.status === 'approved' &&
                                    <Link key={withdrawal.id} onClick={()=>setCurrentwithdrawal(withdrawal)} data-bs-toggle="offcanvas" data-bs-target="#admin-withdrawal-history-menu"  to="#" className="d-flex pb-3">
                                        <div className="align-self-center">
                                            <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                                        </div>
                                        <div className="align-self-center ps-1">
                                            <h5 className="pt-1 mb-n1">{getAmount(withdrawal.amount)}</h5>
                                            <p className="mb-0 font-11 opacity-50">{getDate(withdrawal.created_at)}</p>
                                        </div>
                                        <div className="align-self-center ms-auto text-end">
                                            <h4 className="pt-1 mb-n1 color-green-dark">{withdrawal.user.name}</h4>
                                            <p className="mb-0 font-12 opacity-50">{withdrawal.user.email}</p>
                                        </div>
                                    </Link>
                                ))                
                                        
                            }
                        </div>                                          
                </div>
                
                {/* <!-- Tab Custom Payments--> */}
                <div className="collapse" id="completed-customers" data-bs-parent="#lend-customers">
                    <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
                    <div className="content mt-0 mb-0">
                        {!completedwithdrawal > 0 ?
                        <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                            <div className="content my-3">
                                <div className="d-flex">
                                    <div className="align-self-center no-click">
                                        <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                                    </div>
                                    <div className="align-self-center no-click">
                                        <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                            No declined withdrawals record avaialable
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                            mywithdrawalHistory.map(withdrawal =>(
                                (withdrawal.status === 'completed' || withdrawal.status === 'declined')  &&
                                <Link key={withdrawal.id} onClick={()=>setCurrentwithdrawal(withdrawal)} data-bs-toggle="offcanvas" data-bs-target="#admin-withdrawal-history-menu"  to="#" className="d-flex pb-3">
                                    <div className="align-self-center">
                                        <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                                    </div>
                                    <div className="align-self-center ps-1">
                                        <h5 className="pt-1 mb-n1">{getAmount(withdrawal.amount)}</h5>
                                        <p className="mb-0 font-11 opacity-50">{getDate(withdrawal.created_at)}</p>
                                    </div>
                                    <div className="align-self-center ms-auto text-end">
                                        <h4 className="pt-1 mb-n1 color-theme">{withdrawal.user.name}</h4>
                                        <p className="mb-0 font-12 opacity-50">{withdrawal.user.email}</p>
                                    </div>
                                </Link>
                            ))                
                        
                        }
                    </div>                   
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Adminwithdrawal