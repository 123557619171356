import React, {useEffect, useState} from 'react';
import {shareholdersHistory} from '../Request/Admin';
import { Link } from 'react-router-dom';

const AdminShareholders = () => {

  const setCurrentshare = (share) => {
    localStorage.setItem('adminhistorysharesSelected', JSON.stringify(share));
    const shareEvent = new Event('adminhistorysharesSelected');
    window.dispatchEvent(shareEvent);
}

    let shares = shareholdersHistory();
    useEffect(() => {
      document.title = 'ADMIN LIST SHAREHOLDERS';
      shares = shareholdersHistory();
        if(window && window.init_template){
            window.init_template();
        }  
    });


  return (
    <div id="page-content">
        <h2 className='text-center mb-2'>
            ShareHolders Application History
        </h2>

        <div>
  <div className="card card-style">
    <div className="content mb-0">
      <div className="form-custom form-label no-icon mb-3">
        <input type="text" className="form-control rounded-xs" id="c1" placeholder="Search here.. - try your email" data-search />
        <label htmlFor="c1" className="color-theme">Search</label>
        <span>(required)</span>
      </div>
      <div className="search-results disabled-search-list">
        <div className="list-group list-custom-large">
          
        </div>
      </div>
      <div className="pb-2" />
    </div>
  </div>
</div>



        <div className="">
        <div className="content mt-0 mb-0">
          
            <div className="table-responsive search-results disabled-search-list">
              <table className="table color-theme mb-2">
              <thead>
                <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Type</th>                  
                <th scope="col">Status</th>                  
                </tr>
                </thead>
                <tbody>
                
                {shares.map(share => (
                <tr key={share.user.id} data-filter-item data-filter-name={`${share.user.name.toLowerCase()}  ${share.user.email} ${share.user.phone} ${share.user.country.toLowerCase()} `}>
                  <td>{share.user.name}</td>
                  <td>{share.user.email}</td>
                  <td>{share.type}</td>
                  <td><Link to='#' data-bs-toggle="offcanvas" data-bs-target="#admin-shares-history-menu" onClick={()=>setCurrentshare(share)} className=''>View Details</Link></td>
                  <td> 
                    <div className=''>
                        {share.status}
                    </div>
                </td>
                </tr>
                ))}						
                </tbody>
              </table>
            </div>

            <div className="search-no-results disabled mt-4">
            <div className="card card-style">
              <div className="content">
                
              </div>
            </div>
          </div>


        </div>
    </div>
    </div>
  )
}

export default AdminShareholders