import React, {useEffect, useState} from 'react'
import { plansHistory } from '../Request/Admin';
import { Link } from 'react-router-dom';


const Plans = () => {
    let myplans = plansHistory();
    const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);

    const setCurrentplan = (plan) => {
        localStorage.setItem('adminhistoryplanSelected', JSON.stringify(plan));
        const planEvent = new Event('adminhistoryplanSelected');
        window.dispatchEvent(planEvent);
    }
    useEffect(() => {
        document.title = 'ADMIN INVESTMENT PLANS';
        myplans = plansHistory();
    });
  return (
    <div id="page-content">
        
        <Link to="/withdrawal" data-bs-toggle="offcanvas" data-bs-target="#admin-plan-create-menu" className="default-link card card-style" style={{height: "90px"}}>
            <div className="card-center px-4">
                <div className="d-flex">
                    <div className="align-self-center">
                        <span className="icon icon-m rounded-s gradient-green shadow-bg shadow-bg-xs">
                        <i className="material-icons-outlined color-white" style={{fontSize: "28px", marginTop: "11px"}}>money_off_csred</i>
                        </span>
                    </div>
                    <div className="align-self-center ps-3 ms-1">
                        <h1 className="font-20 mb-n1">Create Plan</h1>
                        <p className="mb-0 font-12 opacity-70">Add new invstment plan</p>
                    </div>
                </div>
            </div>
        </Link>
        <h2 className='text-center mb-2'>
            plans History 
        </h2>

        {!myplans.data.length > 0 ? <div>No plans avaialable yet!</div>:
        <div className='content mt-0 mb-0 card shadow-sm bg-theme color-theme p-3'>
            {myplans.data.map(plan =>(
            <Link key={plan.id} onClick={()=>setCurrentplan(plan)} data-bs-toggle="offcanvas" data-bs-target="#admin-plan-history-menu"  to="#" className="d-flex pb-3">
                <div className="align-self-center">
                    <span className="icon rounded-s me-2 gradient-red shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                </div>
                <div className="align-self-center ps-1">
                    <h5 className="pt-1 mb-n1">{getAmount(plan.min_amount)}</h5>
                    <p className="mb-0 font-11 opacity-50">{plan.ref_bonus} % referral</p>
                </div>
                <div className="align-self-center ms-auto text-end">
                    <h4 className="pt-1 mb-n1 color-red-dark">{getAmount(plan.max_amount)}</h4>
                    <p className="mb-0 font-12 opacity-50">{plan.durations_copy}</p>
                </div>
            </Link>
            ))}
        </div>        
        }
    </div>
  )
}

export default Plans