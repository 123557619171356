import React, {useState, Fragment, useEffect} from 'react'
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import {
    triggerSuccess,
    triggerError,
    setErrorMessage,
    setSuccessMessage,
  } from '../Request/StatusFormatter';
import ChartBalance from '../Reinvestment/ChartBalance';
import EarningSlides from '../Investment/EarningSlides';
const Transfer = () => {
    const initialTransfer = {
        amount: '',
        accountNumber: '',
        transferType: '',
        interAccountNumber: '',
        receiverEmail: '',
        bankName: '',
        swiftCode: '',
        routineNumber: '',
        country: '',
        description: '',
        transferPin: ''
    }
    const [error, setErros] = useState('');
    const [receiver, setReceiver] = useState(null);
    const [countries, setCountries] = useState(null);
    const [transferForm, setTransferForm] = useState(initialTransfer);
    const onChange = (e) =>
    setTransferForm({ ...transferForm, [e.target.name]: e.target.value });


const onKeyUp = (e) => {
  let number = e.target.value;
  let count = number.toString().length;
  if (count === 10) {
    showDetails();
  }
}
    const { isLoading: isSendingRequest, mutate: postTransfer } = useMutation(
        
        async () => {
          return await apiClient.post(`/api/transfer`, {
            amount: transferForm.amount,
            account_number: transferForm.accountNumber,
            transfer_type: transferForm.transferType,
            interAccountNumber : transferForm.interAccountNumber,
            receiverEmail : transferForm.receiverEmail,
            bankName : transferForm.bankName,
            swiftCode : transferForm.swiftCode,
            routineNumber : transferForm.routineNumber,
            country : transferForm.country,
            description : transferForm.description,
            transferPin: transferForm.transferPin
                       
          });
        },
        {
          onSuccess: (res) => {
            setSuccessMessage(res.data);
            triggerSuccess();
            setTransferForm({...transferForm, 
                amount: 0,
                accountNumber: '',
                transferType: ''
            })
            setReceiver(null);
            setErros('')
          },
          onError: (err) => {    
            let myerror = err.response?.data || err; 
            setErrorMessage(err.response?.data || err);
            triggerError();
            if (myerror.errors) {
                setErros(myerror.errors);
            }           
            
          },
        }
      );


      const { isLoading: isFetchingDetails, mutate: showDetails } = useMutation(
        
        async () => {
          return await apiClient.post(`/api/transfer-details/${transferForm.accountNumber}`);
        },
        {
          onSuccess: (res) => {
            setSuccessMessage(res.data);
            setReceiver(res.data.user);
          },
          onError: (err) => {    
            setErrorMessage(err.response?.data || err);         
            triggerError();
            setReceiver(null);
          },
        }
      );
    


      const { isLoading: isFetchingCountry, mutate: loadCountry } = useMutation(        
        async () => {
          return await apiClient.get(`/api/get-countries`);
        },
        {
          onSuccess: (res) => {
            setSuccessMessage(res.data);
            setCountries(res.data.countries);
            
          },
          onError: (err) => {
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );

      useEffect(() => {
        loadCountry()
      }, []);
      const { transferType } = transferForm;
  return (
    <div className="container px-sm-4 px-0" style={{marginTop: '40px'}}> 
      <EarningSlides/>
        <div className="card bg-theme shadow-sm mt-2" style={{borderRadius: '10px'}}>
                <div className="d-flex mx-3 mt-3 py-1">
                    <div className="align-self-center">
                        <h1 className="mb-0 text-center">TRANSFER FUNDS TO ANOTHER BEVY CAPITAL INVESTOR</h1>
                    </div>
                </div>
                <div className="divider divider-margins mt-3"></div>
                <div className="content mt-0">
                <span className='text-danger'>{error !== '' && error.transferType}</span>  
                    <div className="form-custom form-label form-icon">      
                        
                        <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>account_balance_wallet</i>
                        <select onChange={onChange} name="transferType" className="form-select bg-theme color-theme rounded-xs" id="transferType" aria-label="Floating label select example">
                            <option value="">Select Option </option>
                            <option value="local">Inter-account Transfer</option>
                            {/* <option value="bonus">Bonus Balance ({getAmount(bonBalance)})</option> */}
                            {/* <option value="paystack">Referral Balance (&#8358; 20,000)</option>                            */}
                        </select>
                        <label htmlFor="withdrawWallet" className="form-label-always-active color-highlight font-11">Choose Transfer Type </label>
                    </div>
                    
                    <div className="pb-3"></div>
                    
                    <span className='text-danger'>{error !== '' && error.amount}</span>
                    <div className="form-custom form-label form-icon">
                        <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>edit</i>
                        <input onChange={onChange} type="number" name="amount" className="form-control bg-theme color-theme rounded-xs" id="withdrawAmount" placeholder="enter amount"/>
                        <label htmlFor="withdrawAmount" className="form-label-always-active color-highlight font-11">Amount</label>
                        <span className="font-10">( Currency: USD )</span>
                    </div>
                    <div className="pb-2"></div>

                    {/* <div className="pb-2"></div> */}
                    {/* <div className="form-custom form-label form-icon">
                    <i translate="no" className="material-icons-sharp" style={{fontSize: "17px"}}>currency_bitcoin</i>
                        <select onChange={onChange} type="text" name="walletId" className="form-control bg-theme text-theme rounded-xs" id="walletId" placeholder="select wallet">
                            <option value="">Select Payment Method</option>
                        </select>
                        <label htmlFor="walletId" className="color-highlight form-label-always-">Payment Method</label>
                        <span>(required)</span>
                    </div> */}

                    {transferType === 'local' &&
                    <Fragment>
                      <div className="pb-3"></div>
                      <span className='text-danger'>{error !== '' && error.accountNumber}</span>
                    <div className="form-custom form-label form-icon">
                    
                        <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>edit</i>
                        <input onChange={onChange} onPaste={onKeyUp} onKeyUp={onKeyUp} type="text" name="accountNumber" className="form-control bg-theme color-theme rounded-xs" id="accountNumber" placeholder='enter account number'/>
                        <label htmlFor="accountNumber" className="form-label-always-active color-highlight font-11">ACCOUNT NUMBER</label>
                        <span className="font-10">( required )</span>
                    </div>
                    <div className="pb-2"></div>
                    </Fragment>
                    
                    }
                    

                    {transferType === 'Interbank Transfer' &&
                      <Fragment>

                    <div className="pb-3"></div>
                    <span className='text-danger'>{error !== '' && error.interAccountNumber}</span>
                    <div className="form-custom form-label form-icon">
                    
                        <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>edit</i>
                        <input onChange={onChange} type="text" name="interAccountNumber" className="form-control bg-theme color-theme rounded-xs" id="interAccountNumber" placeholder='enter account number'/>
                        <label htmlFor="interAccountNumber" className="form-label-always-active color-highlight font-11">ACCOUNT NUMBER</label>
                        <span className="font-10">( required )</span>
                    </div>
                    <div className="pb-2"></div>
                    <span className='text-danger'>{error !== '' && error.receiverEmail}</span>
                    <div className="form-custom form-label form-icon">
                    
                        <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>edit</i>
                        <input onChange={onChange} type="text" name="receiverEmail" className="form-control bg-theme color-theme rounded-xs" id="receiverEmail" placeholder='enter receiver email'/>
                        <label htmlFor="receiverEmail" className="form-label-always-active color-highlight font-11">Receiver Email</label>
                        <span className="font-10">( required )</span>
                    </div>
                    <div className="pb-2"></div>
                    <span className='text-danger'>{error !== '' && error.bankName}</span>
                    <div className="form-custom form-label form-icon">
                    
                        <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>edit</i>
                        <input onChange={onChange} type="text" name="bankName" className="form-control bg-theme color-theme rounded-xs" id="bankName" placeholder='enter bank name'/>
                        <label htmlFor="bankName" className="form-label-always-active color-highlight font-11">Bank Name</label>
                        <span className="font-10">( required )</span>
                    </div>
                    <div className="pb-2"></div>
                    <span className='text-danger'>{error !== '' && error.swiftCode}</span>
                    <div className="form-custom form-label form-icon">
                    
                        <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>edit</i>
                        <input onChange={onChange} type="text" name="swiftCode" className="form-control bg-theme color-theme rounded-xs" id="swiftCode" placeholder='SWIFT code/BIC'/>
                        <label htmlFor="swiftCode" className="form-label-always-active color-highlight font-11">SWIFT code/BIC</label>
                        <span className="font-10">( required )</span>
                    </div>
                    <div className="pb-2"></div>
                    <span className='text-danger'>{error !== '' && error.routineNumber}</span>
                    <div className="form-custom form-label form-icon">
                    
                        <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>edit</i>
                        <input onChange={onChange} type="text" name="routineNumber" className="form-control bg-theme color-theme rounded-xs" id="routineNumber" placeholder='Routine Number'/>
                        <label htmlFor="routineNumber" className="form-label-always-active color-highlight font-11">Routine Number</label>
                        <span className="font-10">( required )</span>
                    </div>
                    <div className="pb-2"></div>

                    {!isFetchingCountry && 
                    <Fragment>
                      <span className='text-danger'>{error !== '' && error.country}</span>
                      <div className="form-custom form-label form-icon">
                      
                        <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>edit</i>
                        <select onChange={onChange} type="text" name="country" className="form-control bg-theme color-theme rounded-xs" id="country" placeholder='Sselect country'>
                          <option>select cuntry</option>
                          {countries !== null &&
                          countries.map(country => (
                            <option value={country.name}>{country.name}</option>
                          ))
                          }
                        </select>
                        <label htmlFor="country" className="form-label-always-active color-highlight font-11">Select Country</label>
                        <span className="font-10">( required )</span>
                    </div>
                    <div className="pb-2"></div>
                    </Fragment>
                    }                   
                  </Fragment>
                }

                <span className='text-danger'>{error !== '' && error.description}</span>
                  <div className="form-custom form-label form-icon">
                  
                    <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>edit</i>
                    <textarea onChange={onChange} type="text" name="description" className="form-control bg-theme color-theme rounded-xs" id="description" placeholder='Description'></textarea>
                    <label htmlFor="description" className="form-label-always-active color-highlight font-11">Description</label>
                    <span className="font-10">( required )</span>
                </div>
                <div className="pb-2"></div>

                <span className='text-danger'>{error !== '' && error.transferPin}</span>
                  <div className="form-custom form-label form-icon">
                    <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>edit</i>
                    <input onChange={onChange} type="text" name="transferPin" className="form-control bg-theme color-theme rounded-xs" id="transferPin" placeholder='transfer pin'/>
                    <label htmlFor="transferPin" className="form-label-always-active color-highlight font-11">Enter transfer pin</label>
                    <span className="font-10">( required )</span>
                </div>
                <div className="pb-2"></div>

                

                </div>
              
                {receiver !== null && 
                 <div className="card card-style bg-fade-yellow border border-fade-yellow alert alert-dismissible show fade p-0 mb-3">
                 <div className="content my-3">
                     
                     <div className="d-flex">
                         <div className="align-self-center no-click">
                             <i className="bi bi-info-square font-33 color-yellow-dark d-block"></i>
                         </div>
                         <div className="align-self-center no-click">
                             <p className="text-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                             you are about to make a transfer to <br/> {receiver.name}
                             </p>
                         </div>
                     </div>
                 </div>
             </div>
            }

               {isFetchingDetails && 
               <div className='p-4'>
                <div className="alert bg-fade-green color-green-dark alert-dismissible rounded-s fade show" role="alert">
                   fetching account detils...
               </div>
               </div>}
               {receiver !== null && 
               <div onClick={postTransfer} className="mx-3 mb-3 btn btn-full gradient-green shadow-bg shadow-bg-s">
               {isSendingRequest ?<span>Sending...</span>
               :
               <span>Transfer funds</span>
               }
               </div>
               }

               {transferType === 'Interbank Transfer' &&
               
               <div onClick={postTransfer} className="mx-3 mb-3 btn btn-full gradient-green shadow-bg shadow-bg-s">
               {isSendingRequest ?<span>Sending...</span>
               :
               <span>Transfer funds</span>
               }
               </div>}
                
            </div>
    </div>
  )
}

export default Transfer